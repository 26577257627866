import { useEffect, useReducer, useCallback, useMemo } from "react";
import useClientApi from "@/hooks/use-client-api";

import useSessionStorage from "./use-session-storage";

type DataFetchReducerParams = {
  moreMerge: (data: any, action: any) => any;
  checkHasMore: (params: any) => boolean;
};
type UseApiActionParams = {
  type: string;
  payload?: any;
  isChangingRoute?: boolean;
};
type UseApiStateParams = {
  isLoading: boolean;
  isError: boolean;
  errorData: any;
  isLoadingMore: boolean;
  isErrorOnMore: boolean;
  hasMore: boolean;
  isChangingRoute: boolean;
  data: any;
};
type DataFetchReducerType = (
  state: UseApiStateParams,
  action: UseApiActionParams
) => UseApiStateParams;
export const dataFetchReducer: (
  params: DataFetchReducerParams
) => DataFetchReducerType = ({ moreMerge, checkHasMore }) => (
  state,
  action
) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorData: null,
        isLoadingMore: false,
        isErrorOnMore: false,
        hasMore: false,
        isChangingRoute: false,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorData: null,
        data: action.payload,
        hasMore: checkHasMore(action.payload),
        isChangingRoute: action.isChangingRoute,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorData: action.payload,
        isChangingRoute: false,
      };
    case "FETCH_MORE_INIT":
      return {
        ...state,
        isLoadingMore: true,
        isErrorOnMore: false,
        hasMore: false,
      };
    case "FETCH_MORE_SUCCESS":
      return {
        ...state,
        isLoadingMore: false,
        isErrorOnMore: false,
        data: moreMerge(state.data, action.payload),
        hasMore: checkHasMore(action.payload),
        isChangingRoute: action.isChangingRoute,
      };
    case "FETCH_MORE_FAILURE":
      return {
        ...state,
        isLoadingMore: false,
        isErrorOnMore: true,
        hasMore: false,
        isChangingRoute: false,
      };
    default:
      throw new Error();
  }
};

// By default we just overwrite
const defaultDataFetchReducer = dataFetchReducer({
  moreMerge: (d, a) => a,
  checkHasMore: () => false,
});

type BuildDataForMoreResponse = {
  modifiedBody: any;
  modifiedUrl: string;
};
type BuildDataForMoreParams = {
  body: any;
  url: string;
  state: UseApiStateParams;
};
type BuildDataForMoreType = (
  params: BuildDataForMoreParams
) => BuildDataForMoreResponse;
const defaultBuildDataForMore: BuildDataForMoreType = ({
  body,
  url,
  state,
}) => ({
  modifiedBody: body,
  modifiedUrl: url,
});

enum Method {
  GET = "GET",
  POST = "POST",
}

type UseApiResponse = [state: UseApiStateParams, loadMore: () => Promise<void>];
type DependenciesType = any[];
type PreFetchParams = {
  url: string;
  method: Method | string;
  bodyAsJsonString: string;
  dependencies: DependenciesType;
};
type UseApiProps = {
  url: string;
  method?: Method | string;
  body?: any;
  cache?: boolean;
  reducer?: DataFetchReducerType;
  buildDataForMore?: BuildDataForMoreType;
  dependencies?: DependenciesType;
  preFetch?: (params: PreFetchParams) => void | any;
};
const useApi: (options: UseApiProps, initialData?: any) => UseApiResponse = (
  {
    url,
    method = "GET",
    body = null,
    cache = false,
    reducer = defaultDataFetchReducer,
    buildDataForMore = defaultBuildDataForMore,
    dependencies = [],

    // Note: You want to make this function constant. So either declare it top-level or wrap it in a useCallback
    // Invoked before an API call is fired. If `preFetch` returns a value, this value is used as the result in place of
    // making the API call.
    preFetch = null,
  },
  initialData = null
) => {
  const clientApi = useClientApi();
  const bodyAsJsonString = JSON.stringify(body);

  const key = `${url}-${bodyAsJsonString}`;
  const [cacheData, setCacheData] = useSessionStorage(key, null);

  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    isError: false,
    errorData: null,
    data: cacheData || initialData,
    isLoadingMore: false,
    isErrorOnMore: false,
    hasMore: false,
    isChangingRoute: false,
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" });

      if (preFetch) {
        const result = await preFetch({
          url,
          method,
          bodyAsJsonString,
          dependencies,
        });
        if (result) {
          if (!didCancel) {
            dispatch({
              type: "FETCH_SUCCESS",
              payload: result,
              isChangingRoute: false,
            });
          }
          return;
        }
      }

      try {
        const { payload, isChangingRoute } = await clientApi(url, {
          method,
          data: body,
        });

        if (cache) {
          setCacheData(payload);
        }

        if (!didCancel) {
          dispatch({ type: "FETCH_SUCCESS", payload, isChangingRoute });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: "FETCH_FAILURE", payload: error });
        }
      }
    };

    if (cache && cacheData !== null) {
      dispatch({
        type: "FETCH_SUCCESS",
        payload: cacheData,
        isChangingRoute: false,
      });
    } else {
      fetchData();
    }

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method, bodyAsJsonString, preFetch, ...dependencies]);

  const loadMore = useCallback(async () => {
    dispatch({ type: "FETCH_MORE_INIT" });

    try {
      const { modifiedUrl, modifiedBody } = buildDataForMore({
        body,
        url,
        state,
      });
      const { payload, isChangingRoute } = await clientApi(modifiedUrl, {
        method,
        data: modifiedBody,
      });

      // TODO: Check if we have not cancelled
      // if (!didCancel) {
      dispatch({ type: "FETCH_MORE_SUCCESS", payload, isChangingRoute });
      // }
    } catch (error) {
      console.log("ERROR", error);
      // TODO: Check if we have not cancelled
      // if (!didCancel) {
      dispatch({ type: "FETCH_MORE_FAILURE" });
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method, bodyAsJsonString, state, buildDataForMore, clientApi]);

  const result: UseApiResponse = useMemo(() => [state, loadMore], [
    state,
    loadMore,
  ]);
  return result;
};

export default useApi;
