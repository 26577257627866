import useStorage, { StorageType, ValidationFn } from "./use-storage";

const useSessionStorage = (
  key: string,
  initialValue: any,
  validationFn?: ValidationFn
) => {
  return useStorage(StorageType.SESSION, key, initialValue, validationFn);
};

export default useSessionStorage;
