import React, { createContext, useEffect, useReducer } from "react";

import useApi from "../hooks/use-api";
import useAuth from "@/hooks/use-auth";
import { MHMediaObject } from "../@types/api";
import { GaugeRatingValue } from "../@types/rating";

type RatingObject = {
  raw: number;
  rating: GaugeRatingValue;
};

type RatingDict = {
  [mhid: string]: RatingObject;
};

type SetMhidsParams = any;
type SetFulfilledParams = any;
type UserActionUpdateFunctions = {
  setSeenMhids: SetMhidsParams;
  setSavedMhids: SetMhidsParams;
  setUninterestedMhids: SetMhidsParams;
  setSeenFulfilled: SetFulfilledParams;
  setSavedFulfilled: SetFulfilledParams;
  setUninterestedFulfilled: SetFulfilledParams;
  setRatedDict: (RatingDict?) => void;
};
type UserActionValues = {
  seenMhids: Set<String>;
  savedMhids: Set<String>;
  uninterestedMhids: Set<String>;
  seenFulfilled: MHMediaObject[];
  savedFulfilled: MHMediaObject[];
  uninterestedFulfilled: MHMediaObject[];
  ratedDict: RatingDict;
};
type UserActionStateContext = UserActionValues &
  UserActionUpdateFunctions & {
    isLoading: boolean;
    isError: boolean;
    refreshData: () => void;
  };

enum UserActionType {
  INITIAL_LOAD,
  SET_SEEN_MHIDS,
  SET_SEEN_FULFILLED,
  SET_SAVED_MHIDS,
  SET_SAVED_FULFILLED,
  SET_UNINTERESTED_MHIDS,
  SET_UNINTERESTED_FULFILLED,
  SET_RATED_DICT,
}
type NextDataType<T> = (T) => T | T;

type UserActionUpdateData =
  | NextDataType<Set<String>>
  | NextDataType<MHMediaObject[]>
  | NextDataType<RatingDict>;
type UserActionParams = {
  type: UserActionType;
  data?: UserActionUpdateData;
  initial?: UserActionValues;
};
const getPrevious = ({ type, state }) => {
  switch (type) {
    case UserActionType.SET_SEEN_MHIDS:
      return state.seenMhids;
    case UserActionType.SET_SEEN_FULFILLED:
      return state.seenFulfilled;
    case UserActionType.SET_SAVED_MHIDS:
      return state.savedMhids;
    case UserActionType.SET_SAVED_FULFILLED:
      return state.savedFulfilled;
    case UserActionType.SET_UNINTERESTED_MHIDS:
      return state.uninterestedMhids;
    case UserActionType.SET_UNINTERESTED_FULFILLED:
      return state.uninterestedFulfilled;
    case UserActionType.SET_RATED_DICT:
      return state.ratedDict;
    default:
      return null;
  }
};
const userActionStateReducer = (state, action: UserActionParams) => {
  const { type, data, initial } = action;

  const next =
    typeof data === "function" ? data(getPrevious({ type, state })) : data;

  switch (type) {
    case UserActionType.INITIAL_LOAD:
      return { ...initial, isLoading: false };
    case UserActionType.SET_SEEN_MHIDS:
      return {
        ...state,
        seenMhids: next,
      };
    case UserActionType.SET_SEEN_FULFILLED:
      return {
        ...state,
        seenFulfilled: next,
      };
    case UserActionType.SET_SAVED_MHIDS:
      return {
        ...state,
        savedMhids: next,
      };
    case UserActionType.SET_SAVED_FULFILLED:
      return {
        ...state,
        savedFulfilled: next,
      };
    case UserActionType.SET_UNINTERESTED_MHIDS:
      return {
        ...state,
        uninterestedMhids: next,
      };
    case UserActionType.SET_UNINTERESTED_FULFILLED:
      return {
        ...state,
        uninterestedFulfilled: next,
      };
    case UserActionType.SET_RATED_DICT:
      return {
        ...state,
        ratedDict: next,
      };
    default:
      return state;
  }
};

const noop = () => {};
export const userActionStateContext = createContext<UserActionStateContext>({
  isLoading: true,
  isError: false,
  seenMhids: new Set(),
  setSeenMhids: noop,
  savedMhids: new Set(),
  setSavedMhids: noop,
  uninterestedMhids: new Set(),
  setUninterestedMhids: noop,
  seenFulfilled: null,
  setSeenFulfilled: noop,
  savedFulfilled: null,
  setSavedFulfilled: noop,
  uninterestedFulfilled: null,
  setUninterestedFulfilled: noop,
  ratedDict: {},
  setRatedDict: noop,
  refreshData: noop,
});

// If there is no logged in user, don't bother making the API call.
// We bail out and just return empty data
const preFetch = async ({ dependencies }) => {
  const user = dependencies[0];
  if (!user) {
    return {
      seenFulfilled: [],
      savedFulfilled: [],
      uninterestedFulfilled: [],
      seenContentMhids: [],
      savedContentMhids: [],
      uninterestedMhids: [],
      ratedDict: {},
    };
  }
};

export const UserActionStateProvider = ({ children }) => {
  const auth = useAuth();
  const [{ data: userActionData, isError }, loadMore] = useApi({
    url: "/api/profile/get-user-action-data",
    dependencies: [auth.user],
    preFetch,
  });

  const [state, dispatch] = useReducer(userActionStateReducer, {
    isLoading: true,
    seenMhids: new Set(),
    seenFulfilled: null,
    savedMhids: new Set(),
    savedFulfilled: null,
    uninterestedMhids: new Set(),
    uninterestedFulfilled: null,
    ratedDict: {},
  });
  const {
    seenMhids,
    seenFulfilled,
    savedMhids,
    savedFulfilled,
    uninterestedMhids,
    uninterestedFulfilled,
    ratedDict,
    isLoading,
  } = state;

  useEffect(() => {
    if (userActionData) {
      dispatch({
        type: UserActionType.INITIAL_LOAD,
        initial: {
          seenMhids: new Set(userActionData.seenContentMhids),
          seenFulfilled: userActionData.seenFulfilled,
          savedMhids: new Set(userActionData.savedContentMhids),
          savedFulfilled: userActionData.savedFulfilled,
          uninterestedMhids: new Set(userActionData.uninterestedContentMhids),
          uninterestedFulfilled: userActionData.uninterestedFulfilled,
          ratedDict: userActionData.ratedDict,
        },
      });
    }
  }, [userActionData]);

  const refreshData = () => loadMore();

  const setSeenMhids = (newSeen: NextDataType<Set<String>>) =>
    dispatch({ type: UserActionType.SET_SEEN_MHIDS, data: newSeen });

  const setSeenFulfilled = (newSeen: NextDataType<MHMediaObject[]>) =>
    dispatch({ type: UserActionType.SET_SEEN_FULFILLED, data: newSeen });

  const setSavedMhids = (newSaved: NextDataType<Set<String>>) =>
    dispatch({ type: UserActionType.SET_SAVED_MHIDS, data: newSaved });

  const setSavedFulfilled = (newSaved: NextDataType<MHMediaObject[]>) =>
    dispatch({ type: UserActionType.SET_SAVED_FULFILLED, data: newSaved });

  const setUninterestedMhids = (newUninterested: NextDataType<Set<String>>) =>
    dispatch({
      type: UserActionType.SET_UNINTERESTED_MHIDS,
      data: newUninterested,
    });

  const setUninterestedFulfilled = (
    newUninterested: NextDataType<MHMediaObject[]>
  ) =>
    dispatch({
      type: UserActionType.SET_UNINTERESTED_FULFILLED,
      data: newUninterested,
    });

  const setRatedDict = (newRating: NextDataType<RatingDict>) =>
    dispatch({ type: UserActionType.SET_RATED_DICT, data: newRating });

  return (
    <userActionStateContext.Provider
      value={{
        isLoading,
        isError,
        seenMhids,
        setSeenMhids,
        seenFulfilled,
        setSeenFulfilled,
        savedMhids,
        setSavedMhids,
        savedFulfilled,
        setSavedFulfilled,
        ratedDict,
        setRatedDict,
        uninterestedMhids,
        setUninterestedMhids,
        uninterestedFulfilled,
        setUninterestedFulfilled,
        refreshData,
      }}
    >
      {children}
    </userActionStateContext.Provider>
  );
};
